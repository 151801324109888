import '/src/css/style.scss'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material'
import { GoogleAnalytics } from 'nextjs-google-analytics'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { start } from '@/lib/bugsnag'

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            light: '#5eec3c',
            main: '#3a7430',
            dark: '#014040',
            contrastText: '#fff',
        },
        secondary: {
            light: '#e0f692',
            main: '#abd904',
            dark: '#87a616',
            contrastText: '#000',
        },
        alternate: {
            light: '#abd904',
            main: '#014040',
            dark: '#012727',
            contrastText: '#abd904',
        },
    },
})

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
})

start()

const App = ({ Component, pageProps, err }) => {
    const prefersDarkMode = false //useMediaQuery('(prefers-color-scheme: dark)')

    return (
        <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
            <StyledEngineProvider injectFirst>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <DndProvider backend={HTML5Backend}>
                    {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                    <Component {...pageProps} err={err} />
                </DndProvider>
                <GoogleAnalytics trackPageViews />
            </StyledEngineProvider>
        </ThemeProvider>
    )
}

export default App
